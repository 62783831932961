@charset 'utf-8';
@import '../utilities/variable';
@import '../utilities/mixin';

.settings {

  &.card {
    margin-block-end: 16px;
    writing-mode: horizontal-tb;
    padding: $spacing06;
  }

  .card-title {
    margin-bottom: 20px;
    @include h5();
  }
  .container {
    padding: 0 20px;

    .space-below {
      margin-block-end: 10px;
      writing-mode: horizontal-tb;
    }

    .inner-section {
      h6 {
        font-weight: bold;
        padding-top: 20px;
        border-top: 1px solid $blackwhite100;
        margin-block-end: $spacing03;
        writing-mode: horizontal-tb;
      }

      .help-text {
        font-size: 0.8rem;
        font-style: italic;
      }

      .no-items {
        padding: 20px 0;
        text-align: center;
      }

      .tool-bar {
        margin-top: 20px;
        .input-group-text {
          border: none;
          background-color: $purewhite;
        }
        input {
          border: none;

        }

        .more-act-col {
          text-align: right;
        }

        
      }

      .table {
        margin-block-start: $spacing03;
        thead {
          tr {
            background-color: $blackwhite100;
          }
        }
        tbody { 
          tr {
            display: fl;
            &:nth-child(odd) {
              background-color: $purewhite;
            }
            &:nth-child(even) {
              background-color: $blackwhite50;
            }
            td {
              padding: 0 8px;
              vertical-align: middle;
              .btn {
                padding: 0 16px;
                margin: 8px 0;
              }
            }
          }
        }
      }

      .app-access-picker.show {
        .dropdown-menu.show {
          min-width: 250px;
          .row {
            padding: 4px 0;
            .col-2 {
              max-width: 30px;
              padding: 0, 8px, 0, 12px;
            }
            .col-10 {
              padding: 0;
            }
          }
        }
      }
    }
    .setting-btns {
      .col {
       text-align: right;
       padding-right: 32px;
       .btn {
        margin: 0 0 20px 8px;
       }
      }
    }
  
    table.table-striped {
      margin-block-end: 0;
    }
  }

}

.invite-modal {

  .modal-footer.right-align {
    justify-content: flex-end !important;
  }

  .error-report {
    color: red;
    font-size: .8rem;
  }

  label.error {
    font-size: .8rem;
    color: red;
  }
}