@import '../utilities/variable.scss';
@import '../utilities/mixin.scss';
@import '../utilities/customTheme';
@import '../components/sideNav.scss';
@import '../components/pagination';
@import '../components/alchemy';
@import '../components/ghPartnerProfiles';
@import '../components/integrationApp' ;
@import '../components/supportModal';
@import '../components/coProApp';
@import '../components/dropdowns';
@import '../components/settingsPage';
@import '../components/toastAlert';
@import '../components/dynamicToast';
@import '../components/dynamicModal';
@import '../components/industryAnalysis';
@import '../components/vaccineMarket';
@import '../components/superAdmin';
@import '../components/irc';
@import '../components/logs';
@import '../components/listView';
@import '../components/filterBar';
@import '../components/listPagination';
@import '../components/articleDisplay';
@import '../components/cxCustomStyles';
@import '../components/mktactionsCustomStyles';
@import '../components/gridjstable';
@import '../components/chartSettingsPanel';
@import '../components/superAdminMgmt';
@import '../components/searchPage';
@import '../components/confirmationModal';
@import '../customColorThemes/metaCustomColors';
@import '../components/marketActionsToolbar';
@import '../components/assistant';
@import '../components/custom_pbis';
@import '../components/chineseMarket';
@import './buttons.scss';
@import './dropdowns';

// MAIN LAYOUT STYLES -----------------------------MAIN LAYOUT STYLES----------------------------MAIN LAYOUT STYLES
.App {
  overflow-x: hidden;
}

// styling for app page headers that are h2 ex: News, Intelligence, Market Actions, etc
.page-header {
  margin-bottom: 40px;
  padding: 0;
}

.txt-primary {
  color: $text-primary !important;
}

.txt-secondary {
  color: $text-secondary;
}

//Surface colors ------------------------------------------------------------
.surface-primary-text {
  color: $surface-primary;
}
.surface-primary-background {
  background-color: $surface-primary !important;
}

.surface-brand-primary {
  background-color: $surface-brand-primary !important;
}

//TYPOGRAPHY STYLES--------------------------------TYPOGRAPHY STYLES-------------------------TYPOGROPHY STYLES

// Styling for all html elements

h1 {
  @include h1();
}
h2 {
  @include h2();
}
h3 {
  @include heading-h3();
}
h4 {
  @include h4();
}
h5 {
  @include h5();
}
h6 {
  @include h6();
}

//Class header styles
.h1 {
  @include h1();
}
.h2 {
  @include h2();
}
.h3 {
  @include h3();
}
.h4 {
  @include h4();
}
.h5 {
  @include h5();
}
.h6 {
  @include h6();
}

// Styling for all elements with the className='body-large/medium/small'
.body-large {
  @include bodyLarge();
}
.body-med {
  @include bodyMedium();
}
.body-small {
  @include bodySmall();
}

// Label classes

.label-small {
  @include labelSmall();
}

.label-medium {
  @include labelMedium();
}

.label-large {
  @include labelLarge();
}

// Styling for isolated font weights
.semibold { // font weight 600
  @include weightSemibold();
}

.normal {
  @include weightNormal();
}

.light {
  @include weightLight();
}

//Styles to add subtitle1 large to any element
.subtitle-lg {
  @include subtitle1();
}

//Styles to add subtitle2 small to any element
.subtitle-sm {
  @include subtitle2();
}

//Button styles to add buttonSmall font to elements
.buttonSmall {
  @include buttonSmall();
}

//TEXT SECONDARY CLASS---------------------------------------
.text-secondary {
  color: $text-secondary
}


//TAB STYLES ------------------------------------TAB STYLES --------------------------------------------TAB STYLES
.app-tabs {
  a {
    color: $text-secondary;
    @include labelMedium();
  }

  .nav-item.nav-link.active {
    background-color: transparent ;
  }
  .active {
    color: $text-primary;
  }
  &:hover {
    a {
      color: $text-secondary;
    }
    .active {
      color: $text-primary;
    }
  }
}



// NAV TAB STYLES ------------------------------------NAV TAB STYLES --------------------------------------------NAV TAB STYLES

/* Add a bottom border to the whole tab bar */
.nav-tabs {
  border-bottom: 1px solid $blackwhite100; 
  margin-bottom: -1px;
  position: relative;
  .nav-item {
    .nav-link.active {
      background-color: transparent;
    }
  }
}

/* Individual tab links */
.nav-tabs .nav-link {
  @include label-large();
  color: $text-primary;
  border: none;
  padding-inline: $spacing04;
  padding-block: $spacing03;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  border-radius: 2px;
  padding-bottom: 6px; // Adjust padding to align bottom borders
}

/* Hover effect */
.nav-tabs .nav-link:hover {
  background-color: $surface-light-secondary;
  color: $text-primary;
  border-bottom: 2px solid $blackwhite300; // Ensure hover border matches base line 
  border-radius: 2px;
}

/* Add a bottom border to the active tab that sits flush with the base line */
.nav-tabs .nav-link.active {
  background-color: transparent; // Keep active tab background white 
  border-bottom: 4px solid $LBsecondary500; // Active tab border 
  padding-bottom: 4px; // Ensure border alignment 
}

/* Remove focus outline */
.nav-tabs .nav-link:focus {
  outline: none;
}








//BUTTON STYLES --------------------------------BUTTON STYLES-----------------------------------BUTTON STYLES


.btn-primary {
  @include button();
  padding: 6px 10px;
  color: white;
  border-radius: 2px;
}

.button-secondary {
    @include buttonSmall();
    padding: 6px 10px !important;
    background-color: $blackwhite50 !important;
    color: $blackwhite500 !important;
    border: 1px solid $blackwhite500 !important;
    border-radius: 0px !important;
  }


.btn-primary {
    @include button();
    padding: 6px 10px !important;
    border-radius: 0px !important;
    font-weight: 400 !important;
    letter-spacing: .03em !important;
    svg {
      width: 16px;
      height: 16px;
      margin-inline-start: 4px;
      margin-inline-end: 4px;
    }
}

.icon-btn {
    @include button;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: none;
    border-radius: 2px;
    padding-block: 10px;
    padding-inline: 20px;

    svg {
      margin-inline-end: 4px;
    }

}
//BADGE STYLES -----------------------------------------------------BADGE STYLES---------------------------------------------------------------------
//Badge style for altering to archived etc
.descriptor-badge {
  padding: $spacing02 $spacing03 !important;
  justify-content: center !important;
  align-items: center !important;
  gap: $spacing02 !important;
  border-radius: $spacing01 !important;
  background: $surface-brand-primary !important;
  @include subtitle2();
  color: $text-primary;
}

.badge-radius-lg {
  border-radius: $border-radius-lg !important;
  @include subtitle1();
  color: $blackwhite50;
  padding: $spacing02 $spacing04;
}

//TEXTAREA CLASS STYLES-----------------------------------------------TEXTAREA CLASS STYLES------------------------------------------

.truncate {
  min-width: 100px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.textarea-basic {
  border: none;
  background-color: inherit;
  width: 100%;
  overflow: hidden;
  min-height: 40px;
  max-height: 600px;
}

.resize-none {
  resize: none;
}

//BUTTON STYLES ---------------------------------------------------BUTTON STYLES------------------------------------------------

// // NEW button styles -------------------------------------

// URL DRIVEN APP TAB NAV BUTTONS ---------------------------------------------

.url-driven-topnav {
  
  .tab-nav-btn {
    border: none;
    background-color: transparent;
    border-bottom: solid $spacing02 transparent;
    @include label-large();
    color: $text-primary-light;
    border-radius: 0;
    &:hover {
      padding-block-end: 6px !important;
      background-color: transparent;
      color: $text-primary;
      border-bottom: 2px solid $blackwhite300; // Ensure hover border matches base line 
      border-radius: 0;
  
      &:focus {
        border: none;
        background-color: transparent;
        border-bottom: solid $spacing01 transparent;
        @include label-large();
        color: $text-primary-light;
      }
    }
  }


  .active-tab {
    border-bottom: solid $spacing02 $base-border-brand-primary;
    border-radius: 0;
    &:hover {
      padding-block-end: 6px !important;
      background-color: $purewhite;
      border-bottom: solid $spacing02 $base-border-brand-primary;
      color: $text-primary-light;
    }
    &:focus {
      border-bottom: solid $spacing02 $base-border-brand-primary;
    }
  }
  .tab-nav-btn.active {
    border: none;
    background-color: transparent;
    border-bottom: solid $spacing02 $base-border-brand-primary;
    @include label-large();
    color: $text-primary-light;
    padding-block-end: 6px;
    &:hover {
      padding-block-end: 6px !important;
      background-color: transparent;
      border-bottom: solid $spacing02 $base-border-brand-primary;
      color: $text-primary-light;
    }
    &:focus {
      border-bottom: solid $spacing02 $base-border-brand-primary;
    }
  }
}

.subnav-popover {
  margin-block-start: 1px !important;
  .third-level-nav {
    background: $purewhite !important;
    color: $blackwhite900 !important;
    border: 1px solid $purewhite !important;
    @include label-large();

    &:hover {
      border-radius: $border-radius-sm !important;
      border: solid 1px $border-secondary-light !important;
      background-color: $surface-secondary-light !important;
    }
  }
  .sub-2-nav-item {
    border-radius: $spacing01 !important;
    border: 1px solid $purewhite !important;
    background: $purewhite !important;
    color: $blackwhite900 !important;
    text-align: center;
    @include label-large();
    width: 100%;
    text-align: left;
    &:hover {
      width: 100%;
      border-radius: $border-radius-sm !important;
      border: solid 1px $border-secondary-light !important;
      background-color: $surface-secondary-light !important;
    }
  }

  .level-three {
    border-radius: $spacing01 !important;
    border: 1px solid $purewhite !important;
    background: $surface-primary !important;
    color: $text-primary !important;
    @include label-large()
  }
  
  .level-three-active {
    width: 100%;
    text-align: left;
    border-radius: $spacing01 !important;
    border: 1px solid $border-primary-light!important;
    background: $surface-secondary !important;
    color: $text-primary !important;
    @include label-large()
  }



  .sub-2-nav-item.active {
    min-width: 100%;
    border-radius: $spacing01 !important;
    border: 1px solid $border-primary-light!important;
    background: $surface-secondary !important;
    color: $text-primary !important;
    @include label-large()
  }

  .level-two-col {
    max-width: 173px;
  }
}


// // PRIMARY BTNS
.btn-primary-sm {
  border-radius: 2px;
  background: $surface-brand-primary;
  border: 2px solid $surface-brand-primary !important;
  color: $text-primary;
  @include buttonSmall();
  &:hover {
    background: $surface-brand-primary;
    border: 2px solid $surface-brand-primary !important;
    color: $text-primary;
  }
}

.btn-primary-lg {
  border-radius: 2px;
  background: $surface-brand-primary;
  border: 2px solid $surface-brand-primary !important;
  color: $text-primary;
  @include buttonLarge();
  &:hover {
    background: $surface-brand-primary;
    border: 2px solid $surface-brand-primary !important;
    color: $text-primary;
  }
}

.btn-secondary-sm {
  border-radius: $spacing01 !important;
  border: 1px solid $blackwhite500 !important;
  background: $blackwhite50 !important;
  color: $blackwhite500 !important;
  text-align: center;
  @include buttonSmall();
}

.btn-ghost-sm {
  border-radius: $spacing01 !important;
  border: 1px solid transparent !important;
  background: transparent;
  color: $blackwhite900 !important;
  text-align: center;
  @include buttonSmall();
}

.btn-ghost-lg {
  border-radius: $spacing01 !important;
  border: 1px solid $purewhite !important;
  background: $purewhite !important;
  color: $blackwhite900 !important;
  text-align: center;
  @include buttonLarge();
}


.btn-ghost-border-sm {
  border-radius: $spacing01 !important;
  border: 1px solid $text-primary !important;
  background: $surface-primary !important;
  color: $text-primary !important;
  @include buttonSmall()
}

.btn-ghost-border-lg {
  border-radius: $spacing01 !important;
  border: 1px solid $text-primary !important;
  background: $surface-primary !important;
  color: $text-primary !important;
  @include buttonLarge()
}

//For Button with primary border color
.btn-border-primary-sm {
  border-radius: $spacing01 !important;
  border: 1px solid $border-primary-light!important;
  background: $surface-secondary !important;
  color: $text-primary !important;
  @include buttonSmall()
}

//Styles for button large when display solid or with just text
.btn-lg-invisible {
  @include buttonLarge();
  background-color: $purewhite;
  color: $blue500;
}
.btn-lg-solid {
  @include buttonLarge();
}
//Styles for button small when display solid or with just text Apply Filters Clear Filters btns etc
.btn-sm-invisible-100 {
  @include buttonSmall();
  background-color: $purewhite !important;
  border-radius: 2px !important;
  padding: $spacing02 $spacing03 !important;
  border: 1px solid $purewhite !important;
  width: 100% !important;
  color: $blue500 !important;
}
.btn-sm-solid-100 {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  border: none;
  width: 100%;
}

.btn-sm-solid {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  border: none;
}

.btn-sm-invisible {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  border: none !important;
  background-color: $purewhite !important;
  color: $blackwhite500 !important;
}


.btn-sm-invisible-blue {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  background-color: transparent; // to blend in striped table
  border: none;
  color: $blue500;
}

.btn-sm-invisible-red {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  background-color: transparent; // to blend in striped table
  border: none;
  color: $red500;
}


.btn-sm-invisible-green {
  @include buttonSmall();
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  background-color: transparent; // to blend in striped table
  border: none;
  color: $green500;
}

.btn-sm-invisible-dark {
  @include buttonSmall();
  padding: $spacing02 $spacing03 !important;
  border-radius: 2px !important;
  background-color: transparent !important; // to blend in striped table
  border: none !important;
  color: $text-primary !important;
  &:hover {
    background-color: transparent !important; // to blend in striped table
    border: none !important;
    color: $text-primary !important;

  }
}

// Styles small btn such as submit, cancel
.btn-submit {
  margin-block-start: $spacing02;
  margin-inline-start: $spacing04;
  padding: $spacing02;
  @include buttonSmall();
  border: none;
  border-radius: 2px;
}


.btn-cancel {
  padding: $spacing02 !important;
  @include buttonSmall();
  border: $purewhite !important;
  border-radius: 2px !important;
  background-color: $purewhite !important;
  color: $blue500 !important;
  &:hover {
    background-color: $purewhite !important;
  }
}

// button styles for a bit bigger padding (bottom of create intervention)

.btn-submit-lg {
margin-block-start: $spacing02;
margin-inline-start: $spacing04;
padding: $spacing04;
@include buttonLarge();
border: none;
border-radius: 2px;
}

.btn-cancel-lg {
padding: $spacing04 !important;
@include buttonLarge();
border: $purewhite !important;
border-radius: 2px !important;
background-color: $purewhite !important;
color: $blue500 !important;
&:hover {
  background-color: $purewhite !important;
}
}

.submit-cancel-button {
  text-align: right;
}

//Styles for large button in right side filter/select column
.side-col-btn {
  display: flex;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border: none;
  border-radius: 2px !important;
  color: $purewhite !important;
  @include buttonLarge();
  font-weight: 600;
} 

.delete-btn {
  background-color: $red500 !important;
  border: $red500 !important;
  &:hover { 
    background-color: $red500 !important;
    border: $red500 !important;
  }
}

.cancel-btn-border {
  border-radius: 2px;
  border: 1px solid $blackwhite500 !important;
  color: $blackwhite500 !important;
  background-color: $blackwhite50 !important;
  width: 100%;
  padding-block: $spacing01;
  &:hover {
    background-color: $blackwhite50 !important;
    color: $blackwhite500 !important;
    border-radius: 2px;
    border: 1px solid $blackwhite500 !important;
  }
}

//Styles to add margin right to icons added to btns
.btn-icon {
  margin-inline-end: $spacing03;
}

.cx-tag {
  background-color: $blue500;
  padding-inline: $spacing03;
  padding-block: $spacing02;
  border-radius: 2px;
  svg {
    height: 16px;
    width: 16px;
  }
}
//INPUT STYLES ------------------------------- INPUT STYLES -------------------------------------- INPUT STYLES


//NEW style for input and text fields
.input-underline {
  height: 36px; // all inputs and dropdowns 36px
  max-height: 36px !important; // constrain in case of borders/overflow
  border-radius: $spacing01;
  border: 1px solid var(--borders-border-xlight, #EFEFF1);
  background: var(--surfaces-surface-primary, #FFF);
  padding: $spacing04;
  border-bottom: 1px solid $blackwhite900;
  @include bodySmall();
}

//NEW Date input styles 
.input-date {
  border-radius: $spacing01;
  border: 1px solid $greyscale500;
  background: var(--surfaces-surface-primary, #FFF);
  padding: 15px $spacing04;
  @include bodySmall();
  &:hover {
    border: 1px solid $blackwhite900;
  }
}

// Standard Styles Basic for inputs
.input-2px {
  border-radius: 2px !important;
  border: 1px solid $blackwhite900 !important;
  padding: $spacing04 !important;
  color: $text-primary !important;
  @include bodySmall();
}

//Styles for Date inputs
.date-input {
  padding: $spacing04 !important;
  border: 1px solid $blackwhite500;
  width: 100%;
  background-color: transparent;
  @include bodySmall();
}

//Styles for search field input
.search-input {
  border-radius: 2px !important;
  border: 1px solid $blackwhite900 !important;
  padding-block: $spacing01 !important;
  color: $text-primary !important;
  background-color: transparent !important;
  @include bodySmall();
  input {
    border: none;
    background-color: transparent;
  }
}

.text-input {
  min-height: 80%;
}

// NEW 1/23/25
.checkbox {
    width: 16px;
    height: 16px;
  }
//RICH TEXT EDITOR STYLES--------------------RICH TEXT EDITOR STYLES---------------------------RICH TEXT EDITOR STYLES
.tox-tinymce {
  border-radius: 2px !important;
  border: 1px solid $blackwhite900 !important;
  //TODO: figure out how to change font for the text editor to match design system
}



//NEW Multi React Select Dropdown Styles currently in Market Actions Table View
div.basic-multi-select.css-2b097c-container {
  div.select__control.css-yk16xz-control {
    .css-g1d714-ValueContainer  {
      padding-inline-end: 0px;
      .select__placeholder {
        padding-inline-start: 10px;
        color: $text-primary;
        @include bodyMedium();
      }
      div.css-1rhbuit-multiValue.select__multi-value {
        border-radius: $border-radius-lg !important;
        background-color: $teal !important;
        &:nth-child(2) {
          background-color: $light-purple !important;
        }
        &:nth-child(3) {
          background-color: $peach !important;
        }
      }
    }
    .css-1hb7zxy-IndicatorsContainer {
      .css-tlfecz-indicatorContainer {
        color: $text-primary !important;
      }
      div.select__indicator.select__clear-indicator.css-tlfecz-indicatorContainer {
        svg.css-tj5bde-Svg {
          path{
            display: none !important;
          }
        }
      }
      
    }
  }


  .select__control--is-focused {
    .css-g1d714-ValueContainer {
      div.css-1rhbuit-multiValue.select__multi-value {
        border-radius: $border-radius-lg !important;
        background-color: $teal !important;
        &:nth-child(2) {
          background-color: $light-purple !important;
        }
        &:nth-child(3) {
          background-color: $peach !important;
        }
      }
    }
  }
}


//NEW select-dropdown styles
.select-drop-down {
  .css-1pahdxg-control {
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    padding: 6px;
  }
  .css-yk16xz-control {
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    padding: 6px;

    &:hover {
      border: 1px solid $blackwhite900;
    }

    .css-1wa3eu0-placeholder {
      color: $text-primary;
      padding-inline-start: 10px;
    }
    span {
      display: none;
    }
    svg {
      color: $text-primary;
    }
  }
}

.height-36px {
  .css-1pahdxg-control {
    padding-block: 0;
  }
  .css-yk16xz-control {
    padding-block: 0;
  }
}

.select-sm-typography {
  .css-yk16xz-control {
    .css-1ucc91-singleValue {
      @include bodySmall();
    }
    .css-1wa3eu0-placeholder {
      @include bodySmall();
    }
  }
}
.select-lg-typography {
  .css-yk16xz-control {
    .css-g1d714-ValueContainer {
      .css-1ucc91-singleValue {
        @include bodyLarge();
      }
    }
    .css-1wa3eu0-placeholder {
      @include bodyLarge();
    }
  }
}

//Standard Styles for react-select components
.select-filter {
  padding-inline: 0;
  .css-yk16xz-control {
    padding: $spacing01;
    border-radius: 2px;
    border: 1px solid $blackwhite500;
    background-color: transparent;
    @include bodySmall();
    &:hover {
      border: 1px solid $blackwhite500;
    }
    .css-1wa3eu0-placeholder {
      color: $text-primary;
    }
    span {
      display: none;
    }
    svg {
      color: $text-primary;
    }
  }

  .css-1pahdxg-control {
    padding: $spacing01;
    border-radius: 2px;
    border: 1px solid $blackwhite500;
    @include bodySmall();
  }

  &.tag-selector {
    .css-yk16xz-control {
      border: none;
      .css-g1d714-ValueContainer {
        border-bottom: 1px solid $blackwhite900;
      }
    }
  }
}

//NEW Styles for react-bootstrap custom multi select with showing badges for multi selections
.dropdown-multi-select {
  padding-inline: 0;
  .add-padding{
    padding: 14px $spacing05 !important;
  }
  .remove-padding {
    padding: $spacing04 $spacing05 !important;
  }
  button {
    align-items: center;
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    color: $text-primary;
    width: 100%;
    text-align: left;
    @include bodySmall();
    .badge {
      border-radius: $spacing08;
      padding: $spacing02 $spacing04;
      @include subtitle1();
    }
    .selection_0 {
      background-color: $teal;
    }
    .selection_1 {
      background-color: $light-purple;
      margin-inline-start: $spacing02;
    }
    .selection_2 {
      background-color: $peach;
      margin-inline-start: $spacing02;
    }
 
    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      float: right;
      width: 13px;
      margin-block-start: 4px;
      stroke: $blackwhite900; stroke-width: 1.5px;
    }
  }
  .dropdown-toggle {
    width: 100%;
  }
  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: $spacing02;
    color: $text-primary;
    @include bodySmall();
    border: 1px solid $blackwhite300;
    overflow-x: auto;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-block-start: 0 !important;
  }
}

// NEW styles for standard dropdowns used currently Market Actions and Intervention View Modal
// corresponding dropdowns in the table view are called table-dropdown-select

// NEW DROPDOWN STYLES --------- NEW DROPDOWN STYLES --------- NEW DROPDOWN STYLES --------- NEW DROPDOWN STYLES --------- NEW DROPDOWN STYLES --------- NEW DROPDOWN STYLES --------- 

// This is the new class (1/05/2025) for all basic dropdowns.
// EnumSelectors use a React capital S <Select> or <CreatableSelect> component.
// DropdownDynamicWithSearch and many other dropdowns use the React Bootstrap <Dropdown>, <Dropdown.Toggle>, <Dropdown.Menu>
// .select-new-basic merges the styles of both these components to create one super basic dropdown style.


// UNIFIED SELECT STYLES - works for both React-Select and React-Bootstrap Dropdowns
.select-new-basic {
  // Shared base styles
  @include bodyMedium;
  color: $text-primary;
  max-height: 36px !important;
  background-color: transparent;
  height: 36px !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;

  &:hover,
  &:focus-within {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  // Placeholder styling
  .placeholder {
    font-size: .875rem;
    color: $text-primary;
    //color: #6c757d;
    text-align: left;
    padding-left: 0.75rem;
  }

  // Caret (SVG dropdown arrow) styling
  .caret,
  svg.css-tj5bde-Svg,
  button.dropdown-toggle svg {

    width: 16px; // Adjusted width for Select carets
    height: 16px; // Adjusted height for Select carets
    fill: $text-primary; // Match the text color
    transition: none !important; // Prevent animations
    margin-left: auto;
  }

  // React-Select dropdown
  &.css-2b097c-container {
    .css-yk16xz-control,
    .css-1pahdxg-control { // this seems like a lot but is necessary to keep the parent at 36px high
      width: 100%;
      height: calc(36px - 1px) !important; // Adjust for the fractional border
      min-height: calc(36px - 1px) !important; // Ensure min-height matches
      box-sizing: border-box !important; // Include padding and border in the total height
      border: 1px solid #ced4da !important; // Explicit fractional border width
      padding: 0 !important; // Reset padding to prevent overflow
      line-height: normal !important; // Prevent line-height from adding extra height
      background-color: transparent;

      .css-yk16xz-control {
        border-width: 1px !important; // Force a consistent border width
        height: 35px !important; // Adjust height to account for the 1px border (35px content + 1px border = 36px total)
      }

      .css-g1d714-ValueContainer {
        padding: 0;
        display: flex;
        align-items: center;

        .css-1uccc91-singleValue { // selection
          padding-left: 0.75rem;
        }

        .css-b8ldur-Input { // input for creatable selects
          padding-left: 0.75rem;
        }
        .css-1wa3eu0-placeholder { // placeholder
          @extend .placeholder;
        }
      }

       // Remove the pipe divider
       .css-1okebmr-indicatorSeparator {
        display: none; // Hides the vertical pipe
      }

      .css-1hb7zxy-IndicatorsContainer {
        display: flex;
        align-items: center;

        .css-tlfecz-indicatorContainer svg {
          @extend .caret; // Apply unified caret styles
        }
      }
    }

 // React-Select menu item styles
 .css-4ljt47-MenuList {
  background-color: white;

  .css-yt9ioa-option,
  .css-9gakcf-option {
    padding: 0.375rem 0.75rem;
    color: #495057;
    background-color: white; // Default background

    &:hover,
    &[data-focus="true"] {
      background-color: $surface-secondary; // Light gray for hover
      color: #212529; // Darker text
    }

    &[aria-selected="true"] {
      background-color: $surface-brand-primary; // Light blue for active/selected
      color: #212529;
    }
  }
}
}
  

  // React-Bootstrap Dropdown
  &.dropdown {

    button.dropdown-toggle {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      border: none;
      width: 100%;
     // padding: 0 0.75rem;
      font-size: 1rem;
      color: #495057;
     // text-align: right;
      border: 1px solid #ced4da;
      border-radius: 4px !important;

      &:after {
        content: none; // Remove default Bootstrap tinycaret
      }

      &:hover,
      &:focus {
        //border-color: #80bdff; // blue border on selection
        outline: none;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .col-lg-10 {
          @extend .placeholder;
        }

        .col-lg-2 svg {
          @extend .caret; // Apply unified caret styles
        }
      }
    }

    .dropdown-menu {
      width: 100%;
      background-color: white;
      margin-top: $spacing01 !important; // override base to match the react-select
      border-radius: 4px;
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    }

    .dropdown-item {
      padding: 0.375rem 0.75rem;
      color: #495057;

      &:hover,
      &:focus {
        background-color: $surface-secondary; // Light gray for hover
        color: #212529;
      }

      &:active {
        background-color: $surface-brand-primary; // #C0CFDE for selected
        color: #212529;
      }
    }
  }
}















//Large dropdown select padding 16px
.dropdown-select {
  @include bodyMedium;
  padding-inline: 0;
  button {
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    color: $text-primary;
    width: 100%;
    .placeholder-text {
      @include bodyMedium;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
 
    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      float: right;
      width: 13px;
      stroke: $blackwhite900; stroke-width: 1.5px;
    }
  }
  .dropdown-toggle {
    width: 100%; // fill full width of container
  }
  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: $spacing02;
    color: $text-primary;
    @include bodyMedium();
    border: 1px solid $blackwhite300;
    overflow-x: auto;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-block-start: 0 !important;
    input {
      margin: 12px !important;
      border-radius: 4px;
      border: 1px solid $blue500 ;
      padding: $spacing04;
      max-width: 94% !important; // what is this for?
      &::placeholder {
        @include bodyMedium();
        color: $blue500 !important;
      }
      &:focus {
        outline: none !important;
      }
    }
      label {
        color: $text-primary;
      }
      .checkbox {
        margin-inline-start: $spacing04;
        margin-inline-end: $spacing04;
      }
    }
}



// Dropdown-select without the extra padding around each dropdown item
// used in some checkbox and multi dropdowns
.dropdown-select-compressed{
  @include bodyMedium;
  padding-inline: 0;
  button {
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    color: $text-primary;
    width: 100%;
    .placeholder-text {
      @include bodyMedium;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
 
    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      float: right;
      width: 13px;
      stroke: $blackwhite900; stroke-width: 1.5px;
    }
  }
  .dropdown-toggle {
    width: 100%; // fill full width of container
  }
  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: $spacing02;
    color: $text-primary;
    @include bodyMedium();
    border: 1px solid $blackwhite300;
    overflow-x: auto;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-block-start: 0 !important;
    input {
      border-radius: 4px;
      border: 1px solid $blue500 ;
      padding: $spacing04;
      max-width: 94% !important;
      &::placeholder {
        @include bodyMedium();
        color: $blue500 !important;
      }
      &:focus {
        outline: none !important;
      }
    }

    .dropdown-item {
      &:focus, &:hover {
        background-color: $surface-secondary;
      }
      &:active {
        // temporarily leaving blank
       // background-color: $surface-primary;
      }
      
      label {
        color: $text-primary;
      }
      .checkbox {
        margin-inline-start: $spacing04;
        margin-inline-end: $spacing04;

      }
    }
    }
}
// ---------------------------------- Table Dropdowns ---------------------------------------------------//

// used in table view in Market Actions, corresponding with the dropdown-select styles in the modals
.table-dropdown-select {
  position: absolute;
  @include bodyMedium;
  padding-inline: 0;
  width: 100%;

  button {
    border-radius: $border-radius-sm;
    border: 1px solid $greyscale500;
    background: $surface-primary;
    padding: $spacing05 !important;
    color: $text-primary;
    width: 100%;
    .placeholder-text {
      @include bodyMedium;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
 
    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      float: right;
      width: 13px;
      stroke: $blackwhite900; stroke-width: 1.5px;
    }
  }
  .dropdown-toggle {
    width: 100%; // fill full width of container which is the <td> in the table
  }
  .table-dropdown-menu {
    position: absolute;
    margin-block-start: $spacing02;
    border-radius: $spacing02;
    color: $text-primary;
    @include bodyMedium();
    border: 1px solid $blackwhite300;
    overflow-x: auto;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding-block-start: 0 !important;
    input {
      margin: 12px !important;
      border-radius: 4px;
      border: 1px solid $blue500 ;
      padding: $spacing04;
      width: 94% !important;
      &::placeholder {
        @include bodyMedium();
        color: $blue500 !important;
      }
      &:focus {
        outline: none !important;
      }
    }
      label {
        color: $text-primary;
      }
      .checkbox {
        margin-inline-start: $spacing04;
        margin-inline-end: $spacing04;

      }
    }
}













// NEW KG 10/15/2024
// ENUM dropdown select to match dropdown-select styles. Lots of overrides to remove the bootstrap blue -in PMT modal on Vaccine Roadmap
.enum-dropdown-select {
  padding-inline: 0;
  @include bodyMedium();

  // Main control (input area)
  .css-yk16xz-control {
    border-radius: $border-radius-sm !important;
    border: 1px solid $greyscale500 !important;
    background: $surface-primary;
    color: $text-primary;
    width: 100%;
    box-shadow: none !important; // Remove blue shadow on focus

    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }

    &:focus,
    &:focus-within,
    &.css-1pahdxg-control {
      border-color: $greyscale500 !important;
      border-radius: $border-radius-sm !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:not(:focus),
    &:not(:focus-within) {
      border-color: $greyscale500 !important;
      border-radius: $border-radius-sm !important;
    }

    .css-g1d714-ValueContainer {
      @include bodyMedium();
      color: $text-primary;
    }

    .css-1uccc91-singleValue {
      color: $text-primary;
      @include bodyMedium();
    }

    .css-1hb7zxy-IndicatorsContainer {
      svg {
        float: right;
        width: 16px;
        stroke-width: 0.5px;
      }
    }
  }

  // Dropdown menu
  .css-26l3qy-menu {
    position: absolute;
    z-index: 9999 ;
    border-radius: $spacing02 ;
    color: $text-primary ;
    background-color: $purewhite ;
    @include bodyMedium();
    border: 1px solid $blackwhite300;
    max-height: 300px ;
    

    // Adjust menu item hover and focus states
    .css-1n7v3ny-option, .css-9gakcf-option, .css-yt9ioa-option  {
      background-color: $purewhite;
      color: $text-primary;

      &:hover {
        background-color: $greyscale200 !important; // Light gray hover effect
      }

      &:focus {
        background-color: $greyscale200 !important; // No blue on focus
      }

      &.css-9gakcf-option--is-focused,
      &.css-9gakcf-option--is-selected  {
        background-color: $greyscale200 !important; // No blue when focused
      }
    }
  }

  .css-b8ldur-Input input {
    color: $text-primary;
  }

  .css-1pahdxg-control {
    &:hover,
    &:focus,
    &:focus-within {
      border-color: $greyscale500 !important;
      border-radius: $border-radius-sm !important;
      outline: none !important;
      box-shadow: none !important; // no blue shadow
    }

    &:not(:focus),
    &:not(:focus-within) {
      border-color: $greyscale500 !important;
      border-radius: $border-radius-sm !important;
    }
  }
}










//Styles for standard dropdowns used in filterbar
.standard-dropdown {
  padding-inline: 0;
  button {
    border-radius: 2px;
    border: 1px solid $bw-500;
    background-color: transparent;
    color: $text-primary;
    width: 100%;
    @include bodyMedium();
    padding: $spacing04 !important;
    &:hover {
      background-color: $purewhite;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      float: right;
      width: 13px;
      stroke: $blackwhite900; stroke-width: 1.5px;
    }
  }
  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: 4px;
    color: $text-primary;
    min-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    @include bodyMedium();
      label {
        @include bodyMedium();
        color: $text-primary;
      }
      .checkbox {
        margin-inline-start: $spacing04;
        margin-inline-end: $spacing04;

      }
    }
  }




  //NEW styles for toolbar dropdowns (as used in Market Actions, PMT Market Actions)
.toolbar-standard-dropdown {
  padding-inline: 0;
  border-radius: 2px;
  
  label {
    @include bodySmall();
  }

  .dropdown-toggle {
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden;
  }

  button {
    border-radius: 2px !important;
    border: 1px solid $blackwhite900;
    background-color: transparent;
    color: $text-primary;
    width: 100%;
    text-align: left;
    @include bodySmall();
    padding: $spacing04 !important;
    &:hover{
      background-color: transparent;
      color: $text-primary;
      --bs-btn-hover-border-color: $blackwhite900;
      --bs-btn-active-border-color: $blackwhite900;
      --bs-btn-active-bg: $purewhite;
      --bs-btn-active-color: $blackwhite900;
    }
    &::after {
       display: none;
    }
    svg {
      width: $spacing05;
      height: $spacing05;
      float: right;
      stroke: $text-primary;
    }
  }

  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: 4px;
    text-align: left;
    color: $text-primary;
    min-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    @include bodyMedium();
  
      label {
        color: $text-primary;
      }
      .checkbox {
        margin-inline-start: $spacing04;
        margin-inline-end: $spacing04;
      }

        .dropdown-item {
          @include bodyMedium();
          overflow: hidden; 
          text-overflow: ellipsis;
          &.active {
            background-color: $surface-light-secondary; 
            color: $text-primary; 
          }
  
          &:hover {
            background-color: #F0F0F0 ; 
            color: $text-primary;
          }
        }
    }
  }



  

// Styles used for aligning DD value to left instead of centered
.dropdown-text-left {
  .dropdown-toggle {
    text-align: left !important;
  }
 }

// Dropdown menu styling
.dropdown-text-left {
  .dropdown-menu {
    margin-block-start: $spacing02;
    border-radius: 4px;
    color: $text-primary;
    min-width: 100%;
    .dropdown-option {
      margin-inline-start: $spacing04;
      input {
        @include bodyMedium();
        margin-block-start: $spacing06;
        margin-inline-end: $spacing03;
      }
      label {
        @include bodyMedium();
        color: $text-primary;
      }
    }
  }
}

.disabled-dropdown { 
  button {
    border-radius: var(--spacing-01, 2px);
    border: 1px solid $blackwhite500 !important;
    background-color: $surface-primary !important;
    color: $text-secondary !important;
  }
}



//TAG STYLING--------------------------------------------TAG STYLING----------------------------------------TAG STYLING

//styling for all tags
.tag-btn {
  padding: $spacing02 $spacing03;
  border-radius: 2px;
  @include subtitle1();
  margin-inline-end: $spacing04;
  margin-block-end: $spacing04;
  span {
    margin-inline-end: $spacing02;
  }
  svg {
    margin-inline-end: $spacing02;
  }
}

.main-body-text.row {
  min-width: 100% !important;
}

.row.extra-spacer {
  margin-block-end: $spacing05
}

//TOOLTIP STYLES ----------------------------------------TOOLTIP STYLES----------------------------------TOOLTIP STYLES

#tooltip-top {
  opacity: 1;
  .tooltip-inner {
    max-width: 500px;
    background-color: $surface-primary;
    opacity: 1;
    color: $text-primary;
    @include bodyMedium();
    text-align: left;
    box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.13);    border-radius: 2px;
    padding: $spacing03 $spacing05;
    ul {
      margin-block-end: 0 !important;
      padding-inline-start: $spacing05;
    }
  }
} 

//BREADCRUMB STYLES -------------------------------------BREADCRUMB STYLES-------------------------------BREADCRUMB STYLES

.breadcrumb {
  margin-block-start: $spacing06;
  margin-block-end: $spacing03 !important;
  @include bodySmall();

  .breadcrumb-item {
    a {
      color: $text-secondary;
      text-decoration: none !important;
      .active {
        color: $blue500;
      }
    }
  }
  .active {
     text-decoration: underline;
     color: $blue500 !important;
    &:hover {
      cursor: pointer;
    &:last-child {
      color: $blue500 !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
    }
  }
  .not-active {
    text-decoration: none;
    color: $text-primary;
    &:hover {
      color: $blue500;;
    }
  }
}

/* Custom Styles for Industry Analysis Breadcrumb */

li.breadcrumb-item.breadcrumb-custom { // changes / to >
  &:before {
    content: '>';
  }
  .active {
    color: $blue500 !important;
  }
}




li.breadcrumb-item.secondary-link {
  background-color: transparent;
  border: 1px transparent solid;
  &:before {
    content: '>';
  }
  &:first-child {
    &::before {
      display: none;
    }
  .active {
    color: $blue500;
  }
  }
}

.custom-breadcrumb {
  color: $blue500;
}

//BASIC MODAL STYLES-------------------------BASIC MODAL STYLES----------------------------------------BASIC MODAL STYLES
//Modal page alignment
.modal-alignment {
  position: absolute;
  left: 300px;
  top: 108px;
}

//NEW style for modal popups for things such as interventions, etc
.basic-dialog-styles {
  padding: $spacing09;
  min-width: 50vw;
  border-radius: 0px;
  border-left: 2px solid $blackwhite500;
  border-top: none;
  border-right: none;
  border-bottom: none;
  background: $surface-primary;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.18), 0px 27px 58px 0px rgba(0, 0, 0, 0.22);

}

.modal-footer {
  .row {
    width: 100%;
    .col {
      align-items: center;
    }
  }
}

.first-signup-modal {
  top: 20%;
}
.user-signup-modal {
  .modal-dialog {
    max-width: 550px;
    vertical-align: middle !important;
    .modal-content {
      border-left: 2px solid var(--borders-border-primary, #5E6171);
      border-radius: 2px;
      padding: $spacing09;
      h3 {
        @include h3();
        margin-block-end: $spacing06;
      }
      p {
        @include bodyLarge();
        margin-block-end: $spacing06;
        &:last-child {
          margin-block-end: 0;
        }
        button {
          border: none;
          background-color: $purewhite;
          text-decoration: underline;
          color: $blue500;
          padding-inline: 0;
        }
      }
      label {
        @include subtitle1();
        margin-block-end: $spacing03;
      }
      input {
        border-radius: 4px;
        border-bottom: 1px solid var(--bwbw-900, #27292F);
        opacity: 0.8;
        background: var(--surfaces-surface-secondary, #EFEFF1);
        box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
        margin-block-end: $spacing06
      }
      .dropdown-toggle {
        border-radius: var(--spacing-spacing-01, 2px);
        border: 1px solid var(--Primative-Colors-Greyscale-greyscale-500, #ADB5BD);
        margin-block-end: $spacing06;
      }

      .dropdown-menu {
        .dropdown-item {
          padding-block: 0                       ;
        }
      }
      textarea {
        border-radius: 4px;
        border-bottom: 1px solid var(--bwbw-900, #27292F);
        background: var(--bw-pure-white, #FFF);
        box-shadow: 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10), 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
        margin-block-end: $spacing06;
      }
      .btn-primary {
        padding-block: 10px !important;
        min-width: 100%;
        margin-block-end: $spacing06;
        border-radius: 2px;
        background: var(--surfaces-surface-brand-primary, #5D9EFE);
      }
    }
  }
}

//Confirmation modal
// styles for basic confirmation modal

.confirm-modal {
  .modal-content {
    padding: $spacing03 $spacing06 $spacing06 $spacing06;
    width: 574px;

    .modal-header {
      position: relative; 
      border-bottom: none;
      padding: $spacing03 $spacing03; 
      display: flex;
      justify-content: space-between; 

      .h4 {
        @include h3();
        padding-block-start: $spacing04;

        svg {
          margin-inline-start: $spacing03;
          margin-inline-end: $spacing03;
          height: 16px;
          width: 16px;
        }
      }

      button.close {
        display: none;
      }
    }

    .modal-body {
      @include bodyLarge();
    }

    .modal-footer {
      padding: $spacing03 0 0 0;
      border-top: none;

      .row {
        .close {
          padding-inline-end: $spacing03;
        }
        .confirm {
          padding-inline-start: $spacing02;
        }
        margin: 0
      }
    }
  }
}

// Warning Modal

// class to add red border to left side of modal 
.warning-border {
  .modal-content {
    border-inline-start: 4px solid $red500 !important;
  }
}











//COOKIES ICON----------------------------COOKIES ICON--------------------------------------------------
#ot-sdk-btn-floating .ot-floating-button__front {
  background-color: black !important;
 }

.ot-floating-button__front {
  background-image: url("../../assets/lb-oneTrust-cookie.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
 }

 #ot-sdk-btn-floating .ot-floating-button__front svg {
  display: none !important;
 }

 // ----- SIGNIN/SIGNUP PAGE STYLES ----- SIGNIN/SIGNUP PAGE STYLES ----- SIGNIN/SIGNUP PAGE STYLES ----- SIGNIN/SIGNUP PAGE STYLES -----
.signin-landing-page {
  padding: $spacing12 !important;
  min-width: 100vw;
  min-height: 100vh;
  background-image: url('/assets/lb-landing-logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;

  .signin-col {
    padding-inline-end: $spacing09;

    h1 {
      margin-block-end: $spacing06;
      @include h1();
    }

    h3 {
      @include h3();
      margin-block-end: $spacing08;
    }

    .p1 {
      @include bodyMedium();
      margin-block-end: $spacing06;
    }

    .p2 {
      @include bodyMedium();
      margin-block-end: $spacing08;
    }

    .welcome-back {
      padding-inline: $spacing11;
      border: none;
      .card {
        border: none;
      }

      .surface-primary-background {
        border-radius: $spacing01;
        padding-inline: $spacing07;
        padding-block: $spacing08;
        box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.13), 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(10px);

        h3 {
          @include h3();
          margin-block-end: $spacing06;
        }

        p {
          @include bodyMedium();
        }

        .policies-p {
          margin-block-end: 0;
          @include bodyMedium();

          a {
            margin-inline: 2px;
            color: $text-primary;
          }
        }
        button {
          margin-block-end: $spacing06 !important;
          border-radius: $spacing01;
          border: 1px solid $blackwhite500;
          background-color: $blackwhite50;
          color: $blackwhite500;
          width: 100%
        }
      }
    }

    .img-col {
      padding-inline-start: $spacing09;

      img {
        max-height: 576px;
      }
    }
  }
}

//LANDING PAGE STYLES ---------------------------------------------------------------------------------------


.content-row {
  background-color: $surface-primary-light;

    .col {
      padding-inline: 0;
      div.profiles-page.body-container {
        margin-top: 0px;
        margin-block-end: 96px;
        padding-inline-end: 0;
        .isAuth-container {
          margin-inline-start: 38px;
          padding-inline-start: 0;
          .isAuth-landing-page {
            margin-block-start: 96px;
            max-width: 1296px;
            margin-inline: auto;
            @include xl {
              max-width: 1120px !important;
            }
            @include lg {
              max-width: 740px !important;
            }
            @include md {
              max-width: 636px !important;
            }
     
            .wksp-banner-content {
              max-width: 1296px;
              position: relative;
              img {
                max-width: 100%;
              }
  
   
              .header-content {
                position: absolute;
                bottom: 40px;
                left: 40px;
                background: rgba(255, 255, 255, 0.60);
                backdrop-filter: blur(calc(var(--spacing-spacing-04, 12px) / 2));
                margin-inline-end: 40px;
                padding-inline: $spacing09;
                padding-block: $spacing08;
                h3 {
                   @include h3();
                }
                p {
                  @include bodyMedium();
                  margin-block-end: 0;
                }
              }
            }
            .wksp-no-bannerIMG {
              .landing-page-intro {
                h6 {
                  @include bodyMedium();
                }
                p {
                  @include bodyMedium();
                }
              }
            }
  
            .main-apps-collection {
              margin-block-start: $spacing08 !important;
              .col {
                .row {
                  justify-content: space-around;
                  max-width: 100%;
                }
              }
              .app-card-col {
                padding: 0;
                margin-inline: $spacing04;
                margin-block: $spacing04;
                max-width: 306px;
                @include xl {
                  max-width: 356px !important;
                }
                .app-card {
                  border-radius: $border-radius-medium;
                  border: 1px solid var(--borders-border-medium, #9395A0);
                  background: $surface-primary-light;
                  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
                  height: 258px;
                  width: 306px;
                  cursor: pointer;
                  @include xl {
                    height: 280px;
                    width: 356px;
                  }
                  &:hover {
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.13);
                  }
              
                  .tenant-header {
                    height: 120px;
                    padding: $spacing06;
              
                    .tenant-app-name {
                      @include h2();
                      margin-block-end: $spacing03;
                    }
              
                    .card-subtitle {
                      @include label-medium();
                    }
                  }
                  .preview-img-div {
                    border-radius: 8px;
                    max-width: 100% !important;
                    height: 152px !important;
                    position: relative;
                    display: inline-block;
                  }

                  .preview-img-div::before { // image overlay
                    content: "";
                    border-radius: $border-radius-medium $border-radius-medium 0 0; 
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
                    pointer-events: none; 
                    z-index: 1; // keeps overlay above the image but below text 
                  }
                  
                  .preview-card-img {
                    border-radius: $border-radius-medium $border-radius-medium 0 0; 
                    width: 100%;
                    max-height: 152px !important;
                    display: block;
                    object-fit: cover; ; // Prevents stretching and ensures uniform cropping 
                  } 
                  

                    .tenant-header {
                      border-radius: $border-radius-medium $border-radius-medium 0 0; 
                      position: absolute;
                      min-width: 100%;
                      height: 100%;
                      top: 0;
                      left: 0;
                      text-align: left;
                      display: flex; 
                      flex-direction: column; 
                      justify-content: flex-end; 
                      align-items: flex-start;
                      z-index: 2; // keeps SVG and text above the overlay 

                      svg {
                        display: block;
                        margin-block-end: $spacing06;
                        path {
                          color: $text-primary-dark;
                        }
                      }
                      h2 {
                        color: $text-primary-dark;
                        margin-top: auto;
                        max-width: 100%;
                        white-space: normal;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-end;
                      }
                    }


                    
                  }
                  .subtitle-text {
                    .card-subtitle {
                      margin-inline-start: 0;
                      padding: $spacing06;
                      @include label-large();
                    }
                  }
                }
        
              }
              
            }
          }
        }
      }       
    }
  
  
  
  
  .nav-content-col {
    padding-inline: 0 !important;
    min-width: 100%;
    z-index: 1;
  }


.footnote-item {
  width: 100%;
  margin-top: 20px; 
}

.margin-bottom-big {
  margin-bottom: 30px;
}

.full-news-app{
 
  h3 {
    @include h3();
  }

  .app-card.news {
    .story-line-row {
      @include bodyLarge();
      text-align: left;
      margin-bottom: 16px;
      h5 {
        @include h4();
      }
      a {
        color: black !important;
        font-weight: normal;
      }
      .smaller-body {
        margin-bottom: 0;
      }
    }
  }
}

.smaller-weird-corners, .modal-content {
    border-radius: 4px;
}

.pyramid-app-container .border {
  border: none !important;
}

// RELATED NEWS/INTELLIGENCE STYLES ----------------------------------RELATED NEWS/INTELLIGENCE STYLES ----------------------------------------
.related-news-row {

  .container {
    margin-inline: 0;
    min-width: 100%;
  }
}

.related-article-card {
  border-radius: var(--spacing-01, 2px);
  border: 1px solid var(--bwbw-100, #CDCED3);
  background: var(--primative-colors-bw-pure-white, #FFF);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13), 0px 0.5px 1px 0px rgba(0, 0, 0, 0.10);
  min-height: 158px;
  h3 {
    max-height: 72px !important;
    overflow: hidden !important;
  }
  .badge {
    width: fit-content;
    border: none;
    border-radius: var(--spacing-08, 40px);
    padding: $spacing02 $spacing04 !important;
  }
}
// NEWS -------------------------------------------NEWS---------------------------------NEWS


.related-news-card {
  padding: 19px;
  box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
  .news-teaser-sidenav {
    padding: 0;
  }
  .small-title {
    @include h4();
    color: $gray-text;
    margin-top: 3px;
  }
  .company-related-news {
   @include bodyLarge();
    a {
    text-decoration: none;
    color: $tps-dark !important;
    } 
  }

  a.bold-link {
    text-decoration: none;
    @include subtitle1();
    font-weight: bold;
    margin-left: 15px;
  }

  h6 {
    @include h4();
  }

  .story-line-row {
    margin-bottom: 29px;
  }

}

//Aritcle display styles
.article-body {
  img {
    max-width: 100%;
  }
}

.story-teaser {
  color: #212529;
  h5 {
    @include h4();
    padding: 0;
  }
  .smaller-body {
    @include bodyMedium();
    padding: 8px 0;
    margin-bottom: 0;
  }
  small{
    @include bodySmall();
    padding: 8px 0;
  }
}

.story-teaser a {
  color: $text-primary;
  text-decoration: none;
}

div.related-news-card.weird-corners.card {
  margin-bottom: 30px;
}

.modal-content.search-entry-modal {
  background: rgba(0,0,0,.03);
  border: none;
  .modal-header {
    border: none;
    padding: 0;
    justify-content: flex-end;
    color: white;
    button.close {
      background: none;
      color: white;
      font-size: 2rem;
      border: none;
      text-shadow: 0 0 3px #000;
      .sr-only {
        display:none;
      }
    }

    .help-text-modal {
      margin-right: 16px;
    }
  }

  
  .modal-body {
    padding: 0;
  }
}

.modal-content {
  .modal-header {
    position: relative; 
    button.close {
      position: absolute;
      top: 0; 
      right: 0;
      background: none;
      color: grey;
      font-size: 2rem;
      border: none;
      padding-inline-end: $spacing05;
    }

    .sr-only {
      display:none;
    }
  }
}

.text-shadow-bold {
  text-shadow: 0 0 3px #000;
}

div.mb-2.text-muted.card-subtitle.h6 {
  height: 25px;
  color: #32364B;
  @include bodySmall();
}

.co-teaser-logo {
  width: 50%;

  &.big {
    width: 100%;
  }
}

.shadowed {
  box-shadow: 0px 10px 25px rgba(24, 28, 36, 0.05);
}

.profiles_list {
  text-align: left;
  
  .row {
    img {
      &.single_co {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .preview.row {
    margin-bottom: 16px;

    .col {
      flex: none;
    }

    img {
      width:100%;
      height: 60px;
      object-fit: cover;
    }
    
    h6 {
      @include h6();
    }
  }

  .profile-detail {
    padding-top: 15px;
  }
}


.app-list-item.card {

  .btn {
    @include button();
    border: none;
    color: white;
  }

  .link-col {
    text-align: right;
    a {
    color: white;
    @include button();
    svg {
      margin-left: 4px;
    }
    }
  }

  .tag-display {

    .badge {
      margin: 8px 12px 4px 0;
      @include subtitle1();
    }
  }

  h3 {
   @include h3();
   padding:8px 0 4px 0;
   margin: 0;
  } 
  
  p {
    @include bodySmall();
    padding: 8px 0;
    margin-bottom: 0;
  }

  .date-col {
    align-self:center;
  }

  .date-day-num {
    @include h2();
    align-self: center;
    text-align: center;
    color: $pureblack;
    line-height: 1;
  }

  .date-month {    
    text-align: center;
    color: $pureblack;
    @include h5();
  }

  .date-year-num {
    color: $pureblack;
    text-align: center;
    @include h5();
  }
}

figure > img {
  max-width: 100%;
  object-fit: contain;
  border: none;
}

figure > figcaption {
  @include subtitle1;
  font-weight: 300 !important;
  padding-top: $spacing03;
  padding-bottom: $spacing03;
}

// BASE TABLE LOOK ________________________________________________________________________________________________
table {
  border-left: none;
  border-right: none;

  th {
    background-color: $blackwhite100;
    padding: 8px;
    font-weight: 600;
  }

  tr {
    border-left: none;
    border-right: none;
  }

  tr:nth-child(even) {
    background-color: $blackwhite50;
  }
}


// MARKET ACTIONS _____________________________________________________________________________________________________

.market-page-display {
  cursor: pointer;

.market-actions-pbi {
  @include pbiStandard();
}
  // GANTT Settings ---------------------------------------------------------------------------------

 

  .h2-col {
    h2 {
      @include h1();
      padding: 8px 0;
      text-align: left;
    }
  }

  pre {
    display: none;
  }

  .selected-rows {
    display: none;
  }


  .row {



    .add-intervention-col { // "add intervention" and "export" buttons in market actions toolbar
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

    }
  }

  .padded-btn{
    margin-right: 12px;
  }

  .view-display {
    min-height: 600px;
  }

  .view-display.gantt {
    margin-left: $spacing04;
  }

  // TABLE VIEW ---------------------------------------------------------------------------------------
  .dynamic-textarea-wrapper {
    position: relative;
  }

  // style for resizable textarea, that allows all text to be visible and resize when the user types
  .dynamic-textarea {
    resize: none; 
    overflow: hidden; 
    min-height: 40px; 
    height: auto; 
    width: 100%;
  }
  


  .table-column {
    .table-div {
      padding: 0;
      overflow-y: hidden;
      overflow-x: auto;
      border-radius: $border-radius-sm;
     
      table {
        display: inline-block;
        border-spacing: 0;
          
          .intervention-col {
            @include labelMedium();
          }
        

        .hmfCategory {
          @include labelMedium();

        }

        .default-col {
          width: 200px !important;
        }

        .wide-col {
          width: 380px !important;
        }


        thead {
          position: sticky;
          top: 0px;
          margin: 0 0 0 0;
          z-index: 1; 
          th {
            white-space: nowrap;
            padding: $spacing03 $spacing05;
            text-align: left;
            background-color: $blackwhite100;
            @include bodyMedium();
            min-width: 240px !important;
          }
        }
        
        .resizer {
          display: inline-block;
          background: $purewhite;
          width: 2px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          z-index: 1;
          touch-action:none;
        }

        tbody {
          tr {
            min-width: 240px !important;
            height: auto;
            &:nth-child(even) {
              background-color: $surface-secondary;
              textarea {
                background-color: $surface-secondary;
                width: 100%; /* Make textarea take up the full width */
                resize: none; /* Prevent manual resizing */
                overflow: hidden; /* Prevent scrollbars */
              }
              input{
                background-color: $surface-secondary;
                &:focus {
                  background-color: $surface-secondary;
                }
              }
              .css-2b097c-container {
                .css-yk16xz-control {
                  background-color: $surface-secondary;
                }
              }
              .table-dropdown {
                background-color: $surface-secondary;
              }
            }
            &:nth-child(odd) {
              background-color: $surface-primary;
            }
            
            td {
              border: none;
              min-width: 240px !important;
              white-space: nowrap;
              padding: $spacing04;
              vertical-align: top; //Ensure the text starts at the top 
              height: auto; // Ensure cells expand based on content 
              word-wrap: break-word; // Allow text to wrap 
              position: relative;
              textarea {
                 border: none;
              }
              .row {
                align-items: center;
              }
              .click-row {
                height: 100%;
                .col-lg-10 {
                  .truncate {
                    @include bodyMedium();
                    padding: $spacing03 0 $spacing03 $spacing05;
                    vertical-align: middle !important;
                    height: 100%;
                  }
                }
                .icon-col {
                  height: 100%;
                  display: flex;
                  align-items: center !important;
                  justify-content: center;
                }
              }
              
              input{
                border: none;
                padding: $spacing04 10px $spacing03 $spacing05;
                height: 100%;
                max-width: 192px;
                @include bodyMedium();
              }
              .icon-col {
                padding: 1px;
                .info-icon {
                  margin: 0 $spacing05 0 0;
                }
              }

              .css-2b097c-container {
                .css-yk16xz-control {
                  border: none !important;
                  
                  span.css-1okebmr-indicatorSeparator {
                    display: none;
                  }
                }
              }

 
              
              select.table-dropdown {
                padding: $spacing04 $spacing04;
                color: $blackwhite900;
                max-width: 90%;
              }

              .id-link-cell {
                text-align: right;
                .btn {
                  margin-block: 0;
                  border: none;
                  color: $tps-dark;
                }
              }
            }
            .link-col {
              border-right: none;
              max-width: 42px;
              column-width: 42px;
            }
            .market-column {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }

  .badges-picker {
    display: block;
    background-color: $gavi-primary;
    text-align: left;
    margin: 8px;

    .inner-badge-text {
      @include labelMedium();
      display: inline-block;
      width: 85%;
      margin-bottom: 8px;
    }

    button.deselect-button {
      background: none;
      color: white;
      font-size: 1.25rem;
      border: none;
    }
  }

  .select-multi-tag-picker button.dropdown-toggle {
    margin:8px;
    width: 100%;
    background-color: #F3F3F3;
    @include bodySmall();
  }
}

// Market Single Intervention Display -------------------------------------------

.comments {
  @include bodyMedium;
  margin-inline: $spacing01;
  padding-inline: 0 !important;
  .col {
    padding-inline: 0;
  }
  p {
    @include bodyMedium;
    padding-inline: $spacing03;
    margin-block-end: 0;
  }
  .comment-date-col {
    p {
      @include bodyMedium;
      text-align: right;
    }
  }  
}
.history-header {
  border-bottom: solid 1px #C2C2C2;
  padding-block-end: $spacing04;
}
.input-comment-container {
  display:flex;
  padding-inline: $spacing04;
  .input-underline {
    flex: 1;
  }
  .intervention-comment-btns {
      padding-block: 12px !important;
      margin-inline-start: $spacing04;
      max-width: 98px;
  }
}
.btn-container {
  display: flex;
  margin-block-start: $spacing08;

  .left-btn {
    margin-inline-end: auto;
  }
  .right-btn {
    margin-inline-start: auto;
  }
  .btn-row {
    padding-block: $spacing03 !important;
  }
}
// // SUPPORT MODAL ____________________________________________________________________

.bg-surface-tertiary {
  background-color: $surface-light-tertiary;
}

.bg-surface-secondary {
  background-color: $surface-light-secondary;
}

.ma-compilation-panel {
  background-color: $surface-light-secondary;
  padding:10px;
}

//list view --------------------------
.market-actions-display {
  min-width: auto;

  .row.market-rows {
    .row{
      margin-inline-end: 0 !important;
    }

  .mkt-row {
    padding-block: 12px;

    margin: 0 0 0 12px;
    min-width: 100%;
    @include bodyMedium();
    border-bottom: 1px solid var(--borders-border-secondary, $blackwhite300);
    div.p-i-start.col-lg-7 {
      padding-inline-end: $spacing06;
      .mkt-count{
        text-align: right;
      }
    }

    .checkbox-col {
      align-self: center;
      max-width: 20px;
      padding-inline-end: 0;
      padding-inline-start: 0;
    }
    
    .col-md-10 {
      padding-left: 0;
      align-self: center;
      div {
        color: $tps-dark;
        @include bodyMedium;
        padding: 8px 0;
      }
    }
    
    .mkt-name {
      padding-block-start: $spacing03;
      padding-inline-start: 0;
    }

    .col-md-1 {
      padding-right: 0;
    }

    .view-mkt-col {
      text-align: right;
      padding:4px 0 8px 0;
      .tertiary-button {
        align-self: center;
      }
   
    }
  }
  }


  .cross-mkt-btn-row{
    padding-inline-start: 0;
    width: 100%;
    margin-inline-start: 12px;
    margin-block-end: 0;
    border-bottom: 1px solid var(--borders-border-secondary, $blackwhite300);
    .all-mkt-check-col {
      padding-inline-start: 0;
    }
    .selected-mkt-col {
      text-align: right;

      p {
        text-transform: uppercase;
      }
    }
  }
  .end-cross-mkt-btn {
    padding-inline-start: 0;
    width: 100%;
    margin-inline-start: 0;
    margin-block-end: 0;
    margin-block-start: $spacing06;

    .selected-mkt-col {
      text-align: right;

      p {
        text-transform: uppercase;
      }
    }
  }
}


/* NEW Styling for popover container */
#popover-filter-popover {
  .popover-container {
      background: white;
      min-width: 500px;
      max-width: 100%;
      width: 100%;
      padding: $spacing04;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #ADB5BD;
      border-radius: $border-radius-medium;


  }

  .filter-title {
      @include bodyMedium;
      margin-block-end: $spacing04;
      color: $text-primary;
      font-weight: bold;
  }

  .add-filter-wrapper {
      padding-top: 16px; // Add padding above the button for better separation
  }

  .add-filter-btn {
      max-width: 100%;
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 0.875rem; // Match button text size to fit the container
      color: $text-primary;
      font-weight: 600;
      background-color: $surface-light-secondary;
      &:hover {
          background-color: darken($surface-light-secondary, 5%);
      }
  }
}


//kanban --------------

.react-kanban-board {
  margin-bottom: 40px;
  padding-inline-start: 0 !important;

  .react-kanban-column {
    background-color: $surface-light-secondary;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;

    .react-kanban-column-header {
      @include labelMedium()
    }
    .lb-kanban-card {
      background-color: $surface-light-tertiary;
      width: 350px;
      margin: 8px;
      padding: $spacing04;
      border-radius: 2px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    
      p {
        min-height: 20px;
      }
    
      .badge {
        background-color: $gavi-primary;
        border-radius: 2px;
        @include subtitle1;
        padding: 4px 8px;
      }
      .person-responsible {
        @include bodyMedium;
        padding-inline-start: 4px;
      }

      .tool-tip {
        padding-inline-start: 0px;
      }

      a {
        text-decoration: none;
        h4 {
          color: #27292F;
          @include h4();
          padding: 8px 0;
        }
      }
      
    }

    .archived {
      background-color: $surface-light-secondary;
      max-width: 350px;
      padding-inline: $spacing04 $spacing05;
    }
    .archived-badge {
      background-color: $blackwhite400 !important;
      margin-left: $spacing02;
    }
  }
}

//-----------------------------------DEMAND HEALTH----------------------------

.antigen-search {
  max-width: 84px;
}

.demand-activities-table {
  display: flex;
  flex-direction: row;
  
}

.demand-health-activities-table {
  th {
    vertical-align: top;
  }
}


// MEDIA QUERIES ------------------------------------------

@media (max-width: 480px) {
  .profile-popup {
    img.big-photo {
      width: 300px !important;
    }
  } 
}

@media (max-width: 654px) {
  div.isAuth-landing-page {
    max-width: 306px !important;
    .wksp-banner-content {
      img {
        max-width: 306px !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .profiles-page { 
    .isAuth-container {

      div.isAuth-landing-page {
        margin-block-start: $spacing06 !important;
        max-width: 636px ;
        margin-inline: auto;
        .wksp-banner-content {
          img {
            max-width: 636px;
          }
        }
        div.main-apps-collection.row {
          margin-block-start: $spacing08;
          div.app-card-col.col {
            padding: 0;
            margin-inline: $spacing04;
            margin-block: $spacing04;
            .app-card {
              border-radius: $border-radius-medium;
              border: 1px solid var(--borders-border-medium, #9395A0);
              background: $surface-primary-light;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.13);
              height: 258px;
              width: 306px;
              cursor: pointer;
              &:hover {
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.13);
              }

              .preview-img-div {
                border-radius: 8px;
                position: relative;
                display: inline-block;
              }

              .preview-img-div::before { // image overlay
                content: "";
                border-radius: 8px 8px 0 0; 
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
                pointer-events: none; 
                z-index: 1; // keeps overlay above the image but below text 
              }

              .preview-card-img {
                border-radius: 8px 8px 0 0; 
                width: 100%;
                height: 100%;
                max-height: 152px !important;
                display: block;
              }


                .tenant-header {
                  border-radius: 8px 8px 0 0; 
                  position: absolute;
                  min-width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  text-align: left;
                  display: flex; 
                  flex-direction: column; 
                  justify-content: flex-end; 
                  align-items: flex-start;
                  z-index: 2; // keeps SVG and text above the overlay 

                  svg {
                    display: block;
                    margin-block-end: $spacing06;
                    path {
                      color: $text-primary-dark;
                    }
                  }
                  h2 {
                    color: $text-primary-dark;
                    margin-top: auto;
                    max-width: 100%;
                    white-space: normal;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-end;
                  }
                }
          


              .preview-img-div {
                border-radius: $border-radius-medium;
                position: relative;
                display: block;
              }

              .preview-img-div::before { // image overlay
                content: "";
                border-radius: $border-radius-medium $border-radius-medium 0 0; 
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
                pointer-events: none; 
                z-index: 1; // keeps overlay above the image but below text 
              }
              
              .preview-card-img {
                border-radius: $border-radius-medium $border-radius-medium 0 0; 
                width: 100%;
                object-fit: cover;
                max-height: 152px !important;
                display: block;
              }
              

                .tenant-header {
                  border-radius: $border-radius-medium $border-radius-medium 0 0; 
                  position: absolute;
                  min-width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  text-align: left;
                  display: flex; 
                  flex-direction: column; 
                  justify-content: flex-end; 
                  align-items: flex-start;
                  z-index: 2; // keeps SVG and text above the overlay 

                  svg {
                    display: block;
                    margin-block-end: $spacing06;
                    path {
                      color: $text-primary-dark;
                    }
                  }
                  h2 {
                    color: $text-primary-dark;
                    margin-top: auto;
                    max-width: 100%;
                    white-space: normal;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-end;
                  }
                }
              .tenant-header {
                border-radius: $border-radius-medium $border-radius-medium 0 0; 
                height: 100%;
                max-height: 152px;
                padding: $spacing06;
          
                .tenant-app-name {
                  @include h2();
                  margin-block-end: $spacing03;
                }
          
                .card-subtitle {
                  @include label-medium();
                }
              }



              
              .preview-img-div {
                max-width: 100% !important;
                height: 152px !important;
                position: relative;
                .preview-card-img {
                  max-width: 100% !important;
                  height: 100%;
                  //max-height: 152px !important;
                }
                .tenant-header {
                  position: absolute;
                  max-height: 100%;
                  min-width: 100%;
                  max-height: 100%;
                  top: 0;
                  left: 0;
                  text-align: left;
                  svg {
                    display: block;
                    margin-block-end: $spacing06;
                    path {
                      color: $text-primary-dark;
                    }
                  }
                  h2 {
                    color: $text-primary-dark;
                  }
                }
              }
              .subtitle-text {
                .card-subtitle {
                  margin-inline-start: 0;
                  padding: $spacing06;
                  @include label-large();
                }
              }
            }
          }
        }
      }
    }
  }
  
  .icon-btn {
    margin-block-start: 8px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  div.related-news-card {
    .container {
      padding-left: 0;
      padding-right: 0;
    } 
  }
}

@media (max-width: 1200px) {
  .bold-link {
    margin-left: 0 !important;
  }
  .profiles-page {
    min-height: 90vh;
    .header-content {
      position: static !important;
    }
  }
}

@media (min-width: 1200px) {
  .profiles-page {
    min-height: 91vh;
  }

  .preview-img-div {
    max-width: 100% !important;
    height: 152px !important;
    position:relative;
    .preview-card-img {
      max-width: 100% !important;
      height: 100% !important;
    }
  }
}


@media (min-width: 1200px) and (max-width: 1400px) {
 .header-content {
  left:0 !important;
  bottom: 0 !important;
 }
}




// URL DRIVEN APP styles ---------------------------------------------------------------------------------
// APE
.url-driven-app {
  .field-display-pbi {
    @include pbiStandard();
  }
}

// Nigeria app
.nigeria-country-app {
  .field-display-pbi {
    @include pbiTall();
  }
}

// Ethiopia app
.ethiopia-country-app {
  .field-display-pbi {
    @include pbiTall();
  }
}

// Integration app
.integration-app {
  .field-display-pbi {
    @include pbiStandard();
  }
}

